<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmSpcmSessionFormData && Object.keys(vmSpcmSessionFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationspcm_id">
      {{cvSpcmIdLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.spcm_id"
      type="text"
      class="form-control"
      required
    />
</div>
<div class="col-md-12 mb-3">
    <label for="validationsession_id">
      {{cvSpcmIdLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.session_id"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationsession_start_ts">
      {{cvSessionStartTsLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.session_start_ts"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationsession_end_ts">
      {{cvSessionEndTsLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.session_end_ts"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationsession_hosting_platform">
      {{cvSessionHostingPlatformLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.session_hosting_platform"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationsession_meeting_id">
      {{cvSessionMeetingIdLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.session_meeting_id"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationsession_weblink">
      {{cvSessionWeblinkLabel}}</label
    >
    <input
      v-model="vmSpcmSessionFormData.session_weblink"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="spcmSessionEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SpcmSessions } from "../../../FackApi/api/spcmSession"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "SpcmSessionEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSpcmSessionObj: {
      type: Object,
      default: function () {
        return {
          "spcm_id": "",
          "session_id": "",
          "session_start_ts": "",
          "session_end_ts": "",
          "session_hosting_platform": "",
          "session_meeting_id": "",
          "session_weblink": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit spcmSession",
      cvCardSubHeader: "Edit spcmSession",
      cvSubmitBtn: "Edit",
      cvSpcmIdLabel: "spcm id",
      cvSessionStartTsLabel: "session start ts",
      cvSessionEndTsLabel: "session end ts",
      cvSessionHostingPlatformLabel: "session hosting platform",
      cvSessionMeetingIdLabel: "session meeting id",
      cvSessionWeblinkLabel: "session weblink",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "SpcmSession Edit Response",
      vmSpcmSessionFormData: {}
    }
  },
  mounted () {
    this.spcmSessionView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSpcmSessionFormData) {
          if (!this.vmSpcmSessionFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * spcmSessionView
     */
    async spcmSessionView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSpcmSessionFormData = this.propSpcmSessionObj
        }
        else {
          let spcmSessionId = this.$route.params.session_id
          let spcmSessionViewResp = await SpcmSessions.spcmSessionView(this, spcmSessionId)

          if (spcmSessionViewResp && spcmSessionViewResp.resp_status) {
            this.vmSpcmSessionFormData = spcmSessionViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmSessionView() and Exception:", err.message)
      }
    },
    /**
     * spcmSessionEdit
     */
    async spcmSessionEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let spcmSessionAddResp = await SpcmSessions.spcmSessionEdit(this, this.vmSpcmSessionFormData)
        await ApiResponse.responseMessageDisplay(this, spcmSessionAddResp)

        if (spcmSessionAddResp && !spcmSessionAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmSessionEditModal", this.vmSpcmSessionFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmSessionEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
